import * as React from "react"
import { Link, graphql } from "gatsby"

import PageLayout from "../templates/page-layout"
import Seo from "../components/seo"

const Terms = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`

	return (
		<PageLayout location={location} title={siteTitle}>
			<Seo title="Imprint" description="Imprint" />
			
			<div className="relative px-8 py-16">
				<header className="mx-auto max-w-prose text-lg">
					<h1 className="text-5xl font-extrabold">Imprint</h1>
					<p className="text-md mt-2 text-gray-500">Updated at 2022-01-01</p>
				</header>

				<main className="prose prose-lg mx-auto mt-8 text-gray-500">
					<h2 className="m-0 mb-2">Company Name</h2>
					<p>Exploo UG (haftungsbeschränkt)</p>

					<h2 className="m-0 mb-2">Address</h2>
					<p>
						Bernauer Straße 98<br />
						13507 Berlin<br />
					</p>

					<h2 className="m-0 mb-2">Managing Director</h2>
					<p>Ferhat Beyaz</p>

					<h2 className="m-0 mb-2">Contact us</h2>
					<p>
						If you have any questions, do not hesitate to get in touch with us: <Link to="/contact">Contact form</Link>.
					</p>
				</main>
			</div>
		</PageLayout>
	)
}

export default Terms

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`